import React from 'react';

const SvgLogo = () => {
  return (
      <>
        <rect x="3.7878" y=".5632" width="16.931" height="22.951" ry="2.4359" fillOpacity="0.0"  strokeLinecap="round" strokeWidth="2"  />
        <g transform="matrix(.24077 0 0 .24077 .15909 -.42401)" strokeWidth="0" aria-label="s.">
          <path d="m52.292 41.488q-1.4676-1.9262-4.0358-3.302-2.5682-1.4676-5.5951-1.4676-2.6599 0-4.8613 1.1007-2.2013 1.1007-2.2013 3.6689 0 2.5682 2.3848 3.6689 2.4765 1.0089 7.1543 2.1096 2.4765 0.55033 4.953 1.4676 2.5682 0.91722 4.5861 2.4765 2.1096 1.4676 3.3937 3.7606 1.2841 2.2013 1.2841 5.4116 0 4.0358-1.5593 6.8792-1.4676 2.7517-4.0358 4.4944-2.4765 1.7427-5.8702 2.4765-3.302 0.8255-6.8792 0.8255-5.1364 0-9.9977-1.8344-4.8613-1.9262-8.0716-5.4116l7.2461-6.7874q1.8344 2.3848 4.7696 3.9441 2.9351 1.5593 6.5123 1.5593 1.1924 0 2.3848-0.27517 1.2841-0.27517 2.2931-0.8255 1.1007-0.64206 1.7427-1.651 0.64206-1.0089 0.64206-2.4765 0-2.7517-2.5682-3.9441-2.4765-1.1924-7.5212-2.3848-2.4765-0.55033-4.8613-1.3758-2.2931-0.91722-4.1275-2.2931-1.8344-1.4676-2.9351-3.5772-1.1007-2.1096-1.1007-5.2282 0-3.6689 1.4676-6.3288 1.5593-2.6599 4.0358-4.3109 2.4765-1.7427 5.5951-2.5682 3.1186-0.8255 6.4206-0.8255 4.7696 0 9.2639 1.651 4.5861 1.651 7.2461 5.0447z" />
          <path d="m62.848 67.354q0-2.9351 2.1096-5.0447 2.2013-2.1096 5.2282-2.1096 2.9351 0 5.1364 2.0179 2.2013 2.0179 2.2013 4.953 0 2.9351-2.2013 5.0447-2.1096 2.1096-5.1364 2.1096-1.4676 0-2.8434-0.55033-1.2841-0.55033-2.2931-1.4676-1.0089-0.91722-1.651-2.2013-0.55033-1.2841-0.55033-2.7517z" />
        </g>
      </>
  );
}

export default SvgLogo;
